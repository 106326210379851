import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useAppDispatch } from '../../utils/hooks';
import { getImageData } from '../../modules/traffic-analisys/operations';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface PhotoDialogProps {
  open: boolean;
  url: string;
  onClose: () => void;
}

export default function PhotoDialog({ open, onClose, url }: PhotoDialogProps) {
  const [base64, setBase64] = React.useState<string>('');
  React.useEffect(() => {
    const fetchData = async () => {
      if (url && url.length > 0) {
        const result = await getImageData(url);
        setBase64(String(result));
      }
    };
    fetchData();
  }, [url]);

  return (
    <div>
      <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={onClose} aria-describedby="alert-dialog-slide-description">
        <DialogTitle>Podgląd</DialogTitle>
        <DialogContent>
          <img src={base64} width="100%" />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Zamknij</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
