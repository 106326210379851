import { Box, Stack, Typography } from '@mui/material';

interface HeaderProps {
  message: string;
}

const AuthHeaderBox = (props: HeaderProps) => {
  return (
    <Stack direction={'column'} alignItems={'center'} justifyContent={'center'}>
      <Box component='img' src='/images/logo.svg' sx={{
        borderBottom: 1,
        borderTopColor: '#7AB9E5'
      }} />
      <Typography variant='body1' sx={{ my: 2, textAlign: 'center' }}>
        {props.message}
      </Typography>
    </Stack>
  );
};

export default AuthHeaderBox;
