import { Box, CircularProgress, Typography } from '@mui/material';
import { StatBoxType } from '../../modules/location/model';

const grey = '#EDF2F7';

interface NumberCircularProgressProps {
  value: number;
  type: StatBoxType;
}

const NumberCircularProgress = ({ value, type }: NumberCircularProgressProps) => {
  return (
    <Box position="relative" width="192px">
      <Box top={0} left={0} bottom={0} right={0} position="absolute" sx={{ width: 192, height: 192, backgroundImage: getBgImage(type) }}>
        <Box top={13} left={13} bottom={0} right={0} position="absolute" sx={{ width: 192, height: 192 }}>
          <CircularProgress style={{ color: grey }} size={166} variant="determinate" />
        </Box>
        <Box top={0} left={0} bottom={0} right={0} position="absolute" sx={{ width: 192, height: 192, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography color="textSecondary" align="center" sx={{ fontWeight: 800, fontSize: '2rem', color: '#EDF2F7' }}>{`${value}%`}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
const getBgImage = (type: StatBoxType) => {
  switch (type) {
    case StatBoxType.Today:
      return "url('/images/chart.svg')";
    case StatBoxType.WhiteList:
      return "url('/images/chartGreen.svg')";
    case StatBoxType.ParkingBlue:
      return "url('/images/chartBlue.svg')";
    case StatBoxType.ParkingPink:
      return "url('/images/chartPink.svg')";
    default:
      return '';
  }
};
export default NumberCircularProgress;
