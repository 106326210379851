import { Button, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from '@mui/material';
import { useState } from 'react';
import ConfirmationDialog from '../../components/ConfirmationDiaog';
import Pagination from '../../components/Pagination';
import { WhiteListRequest, WhiteListRow } from '../../modules/white-list/models';
import format from 'date-fns/format';
import Checkbox from '@mui/material/Checkbox';

import { deleteWhiteListEntry, fetchWhiteListRows } from '../../modules/white-list/operations';
import { getFilters, getSelectedEntry, getVehicleTypes, getWhiteListRows } from '../../modules/white-list/selectors';
import { openDialog, setFilters, setSelectedEntry } from '../../modules/white-list/slice';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { getLocations } from '../../modules/location/selector';
import { exportData } from '../../modules/white-list/operations';

const DataGrid = () => {
  const allVehicleTypes = useAppSelector((state) => getVehicleTypes(state.whiteList));
  const allLocations = useAppSelector((state) => getLocations(state.location));
  const dispatch = useAppDispatch();
  const rows = useAppSelector((state) => getWhiteListRows(state.whiteList));
  const filters = useAppSelector((state) => getFilters(state.whiteList));

  const selectedEntry = useAppSelector((state) => getSelectedEntry(state.whiteList));
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);

  const handlePageChange = (newPage: number) => {
    const newFilters = { ...filters };
    newFilters.page = newPage;
    refreshGrid(newFilters);
  };

  const handlePageSizeChange = (pageSize: number) => {
    const newFilters = { ...filters, pageSize: pageSize } as WhiteListRequest;
    refreshGrid(newFilters);
  };

  const handleDelete = async (entry: WhiteListRow) => {
    dispatch(setSelectedEntry(entry));
    setOpenConfirmationDialog(true);
  };

  const handleEdit = async (entry: WhiteListRow) => {
    dispatch(setSelectedEntry(entry));
    dispatch(openDialog());
  };

  const handleCloseConfirmationDialog = async (result?: boolean) => {
    if (result && selectedEntry) {
      await dispatch(deleteWhiteListEntry(selectedEntry));
    }

    setSelectedEntry(undefined);
    setOpenConfirmationDialog(false);
  };

  const getVehicleTypeName = (uuid: string) => {
    return allVehicleTypes.find((x) => x.uuid === uuid)?.name;
  };
  const getLocationName = (uuid: string) => {
    return allLocations[0].subLocations.find((x) => x.uuid === uuid)?.name;
  };

  const refreshGrid = (newFilters: WhiteListRequest) => {
    dispatch(setFilters(newFilters));
    dispatch(fetchWhiteListRows(newFilters));
  };

  const handleExportClick = () => {
    dispatch(exportData(filters));
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{
            width: '100%',
            '& tbody th': {
              fontWeight: 800,
            },
          }}
          aria-label="Białe listy"
        >
          <TableHead>
            <TableRow
              sx={{
                '& th': {
                  fontWeight: 800,
                },
              }}
            >
              <TableCell>
                <Checkbox />
              </TableCell>
              <TableCell>Numer rejestracyjny</TableCell>
              <TableCell>Data</TableCell>
              <TableCell>Dni</TableCell>
              <TableCell>Godziny</TableCell>
              <TableCell>Dopuszczone miejsce wjazdu</TableCell>
              <TableCell>Rodzaj pojazdu</TableCell>
              <TableCell>Opis</TableCell>
              <TableCell>Operacje</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.content?.map((row) => (
              <TableRow key={row.uuid}>
                <TableCell>
                  <Checkbox />
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.vehiclePlates}
                </TableCell>
                <TableCell>
                  {row.fromDate === null ? (
                    'Bezterminowo'
                  ) : (
                    <Typography>
                      {format(new Date(row.fromDate), 'dd.MM.yyyy')} - {format(new Date(row.toDate), 'dd.MM.yyyy')}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>{row.daysOfWeek?.length ?? 0 > 0 ? getDays(row.daysOfWeek) : ''}</TableCell>
                <TableCell>
                  {row.fromHour === null ? (
                    'Całodzienny'
                  ) : (
                    <Typography>
                      {format(new Date(row.fromHour), 'HH.mm')} - {format(new Date(row.toHour), 'HH.mm')}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>{getLocationName(row.locationUuid)}</TableCell>
                <TableCell>{getVehicleTypeName(row.vehicleTypeUuid)}</TableCell>

                <TableCell></TableCell>
                <TableCell>
                  <Stack direction="row">
                    <Button variant="contained" color="primary" size="small" style={{ marginLeft: 16 }} onClick={() => handleEdit(row)}>
                      Edytuj
                    </Button>
                    <Button variant="contained" color="secondary" size="small" style={{ marginLeft: 16 }} onClick={() => handleDelete(row)}>
                      Usuń
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={8}>
                <Pagination pageNo={filters.page} totalRows={rows?.total ?? 0} rowsPerPage={filters.pageSize} onPageChange={handlePageChange} onPageSizeChange={handlePageSizeChange}></Pagination>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Grid container sx={{ mt: 1, justifyContent: 'right' }}>
        <Button variant="contained" onClick={handleExportClick}>
          Eksportuj dane
        </Button>
      </Grid>
      <ConfirmationDialog message="Czy na pewno chcesz usunąć wiersz?" title="Usuwanie elementu" onClose={handleCloseConfirmationDialog} open={openConfirmationDialog}></ConfirmationDialog>
    </>
  );
};

const getDays = (fullDayNames: string[]): string => {
  const shortNames = fullDayNames.map((f) => {
    switch (f) {
      case 'monday':
        return 'pn';
      case 'tuesday':
        return 'wt';
      case 'wednesday':
        return 'śr';
      case 'thursday':
        return 'cz';
      case 'friday':
        return 'pt';
      case 'saturday':
        return 'sb';
      case 'sunday':
        return 'nd';
      default:
        return '';
    }
  });

  if (shortNames.length === 1) {
    return shortNames[0];
  }
  const dayList = allDays.filter((d, i, all) => shortNames.find((s) => s === d) !== undefined);

  let hasSequence = true;
  dayList.forEach((d, i, all) => {
    if (i === all.length - 1) {
      return;
    }
    if (allDays.indexOf(d) + 1 === allDays.indexOf(all[i + 1])) {
      hasSequence = true;
      return;
    }
    hasSequence = false;
  });
  if (hasSequence) {
    return dayList[0] + '-' + dayList[dayList.length - 1];
  }
  return dayList.join(', ');
};
const allDays = ['pn', 'wt', 'śr', 'cz', 'pt', 'sb', 'nd'];

export default DataGrid;
