import Modules from "../modules/";
import Partials from "./map/";

const Map = () => {

    const useMap = Modules.useMap();

    return (
        <>
            <Partials.MapView {...useMap}/>
            <Partials.MapZoom {...useMap}/>
            <Partials.MapLegend {...useMap}/>
        </>
    );
}

export default Map;