import Cameras from './Cameras';
import Home from './Home';
import Login from './Login';
import Map from './Map';
import Barriers from './Barriers';
import PasswordReminder from './PasswordReminder';
import TrafficAnalysis from './TrafficAnalisys';
import WhiteList from './WhiteList';
import User from './User';
import PasswordReset from './PasswordReset';

export default {
  Cameras: Cameras,
  Home: Home,
  Login: Login,
  Map: Map,
  ParkingManagement: Barriers,
  PasswordReminder: PasswordReminder,
  PasswordReset: PasswordReset,
  TrafficAnalysis: TrafficAnalysis,
  WhiteList: WhiteList,
  User: User
};
