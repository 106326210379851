import { Button, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import AuthHeaderBox from './login/AuthHeader';
import { useAppDispatch } from '../utils/hooks';
import { logIn } from '../modules/auth/operations';
import { AuthUserDataModel } from '../modules/auth/models';

const LogInForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [loginRequestStatus, setLoginRequestStatus] = useState<string>('idle');
  const canSave = loginRequestStatus === 'idle';

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!canSave) {
      return;
    }
    // // TODO: add validation
    const data = new FormData(event.currentTarget);
    const email = data.get('email')?.toString() ?? '';
    const password = data.get('password')?.toString() ?? '';

    const authData = {
      email: email,
      password: password
    } as AuthUserDataModel;

    try {
      setLoginRequestStatus('pending');
      await dispatch(logIn(authData));

      navigate(searchParams.get('r') ?? '/');
    } catch (err) {
      console.error('Failed to login: ', err);
    } finally {
      setLoginRequestStatus('idle');
    }
  };

  return (
    <>
      <Stack
        spacing={2}
        direction={'column'}
        component='form'
        onSubmit={handleSubmit}
        noValidate
        sx={{
          marginTop: 8
        }}
        alignItems={'center'}
      >
        <AuthHeaderBox message='Aplikacja do zarządzania parkingami' />
        <TextField required fullWidth id='email' label='Adres email' name='email' autoComplete='email' />
        <TextField required fullWidth name='password' label='Hasło' type='password' id='password'
                   autoComplete='new-password' />
        <Button type='submit' variant='contained' sx={{ width: '266px', height: '72px' }}>
          Zaloguj
        </Button>
        <Link style={{ alignSelf: 'flex-start' }} to='/password_reminder'>Zapomniałem hasła</Link>
      </Stack>
    </>
  );
};

export default LogInForm;
