import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { LocationVm, StatisticsLocation } from './model';
import Cookies from 'js-cookie';

export const fetchStructure = createAsyncThunk(
  'location/fetchStructure',
  async () => {
    try {
      const result = await axiosInstance.get<LocationVm[]>(
        '/v1/location/structure'
      );
      Cookies.set('structure', JSON.stringify(result.data), { expires: 1 });
      return result.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const fetchStatistics = createAsyncThunk(
  'location/statistics',
  async (locationUuid: string) => {
    try {
      const result = await axiosInstance.get(
        `/v1/location/${locationUuid}/statistics`
      );
      return result.data as StatisticsLocation[];
    } catch (error) {
      throw error;
    }
  }
);
