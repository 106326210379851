import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Box, Button, Divider, IconButton } from '@mui/material';
import { useAppDispatch } from '../utils/hooks';
import { ProfileIcon } from '../components/Icons';
import { logOut } from '../modules/auth/operations';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import "./App.css";

export default function TopAppBar() {
  const dispatch = useAppDispatch();

  const handleLogout = async () => {
    await dispatch(logOut());
  };

  return (
      <header className="AppBar">
          <a href="/">
            <img className="AppLogo" src="images/logo.svg"/>
          </a>
          <h1 className="AppTitle">Aplikacja do zarządzania parkingami</h1>
        <Toolbar className="AppTolbar" >
            <Button className="AppLogutButton" onClick={handleLogout}>
                <span>Wyloguj</span>
                <img src="images/user.svg"/>
            </Button>
        </Toolbar>
      </header>
  );
}
