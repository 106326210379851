import { CombinedState, configureStore } from '@reduxjs/toolkit';
import { LocationStateModel, LocationVm } from '../modules/location/model';
import { UserStateModel } from '../modules/user/model';
import rootReducer from './reducer';
import Cookies from 'js-cookie';

const getStateFromCookies = (): CombinedState<{
  user: UserStateModel;
  location: LocationStateModel;
}> => {
  let user = {} as UserStateModel;
  let structure = {} as LocationVm[];
  const savedUser = Cookies.get('user');
  const savedStructure = Cookies.get('structure');
  if (savedStructure) {
    structure = JSON.parse(savedStructure) as LocationVm[];
  }
  if (savedUser != undefined) {
    user = JSON.parse(savedUser) as UserStateModel;
  }
  return {
    location: { locations: structure } as LocationStateModel,
    user: user
  };
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: getStateFromCookies()
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
