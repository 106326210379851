import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  message: string;
  onClose: (result?: boolean) => void;
}

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const { onClose, open, title, message, ...other } = props;
  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  return (
    <Dialog sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }} maxWidth="xs" open={open} {...other}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{message}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Anuluj
        </Button>
        <Button onClick={handleOk}>Potwierdź</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
