import { Grid, Paper, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SalesType, StatBoxType, Statistics, StatisticsData, StatisticsType } from '../modules/location/model';
import { fetchStatistics } from '../modules/location/operations';
import { getMainLocation, getStatistics } from '../modules/location/selector';
import { useAppDispatch, useAppSelector } from '../utils/hooks';
import StatisticsBox from './home/StatisticsBox';
import TicketChart from './home/TicketChart';

const Home = () => {
  const { location } = useParams<string>();
  const dispatch = useAppDispatch();
  const mainLocation = useAppSelector((s) => getMainLocation(s.location));
  const stats = useAppSelector((s) => getStatistics(s.location));

  const getStatsData = (statType: StatisticsType): Statistics | undefined => {
    if (stats && stats.length > 0) {
      let data = {
        data: {
          exists: 0,
          online: 0,
          onSite: 0,
          entries: 0,
          takenSpots: 0,
          multiDayTickets: 0,
          availableSpots: 0,
          onlineUsed: 0,
        } as StatisticsData,

        type: {} as StatisticsType,
      } as Statistics;

      stats.forEach((stat) => {
        stat.statistics.forEach((statsByLocation) => {
          data.type = statsByLocation.type;
          // @ts-ignore
          data.data.exists += statsByLocation.data.exists ?? 0;
          // @ts-ignore
          data.data.online += statsByLocation.data.online ?? 0;
          // @ts-ignore
          data.data.onSite += statsByLocation.data.onSite ?? 0;
          // @ts-ignore
          data.data.entries += statsByLocation.data.entries ?? 0;
          // @ts-ignore
          data.data.takenSpots += statsByLocation.data.takenSpots ?? 0;
          // @ts-ignore
          data.data.multiDayTickets += statsByLocation.data.multiDayTickets ?? 0;
          // @ts-ignore
          data.data.availableSpots += statsByLocation.data.availableSpots ?? 0;
          // @ts-ignore
          data.data.onlineUsed += statsByLocation.data.onlineUsed ?? 0;
        });
      });

      if (data) {
        return data;
      }
    }
  };

  const getStatsLocationName = () => {
    if (stats && stats.length) {
      if (stats.length === 1) {
        return stats[0].name;
      }
      return stats.map((x) => x.name.replace('Parking ', '').replace('Wjazd na ', '')).join(', ');
    }
    return '';
  };

  const getSalesData = () => {
    return getStatsData(StatisticsType.TICKET_SALES);
  };
  const getSalesSum = () => {
    const sData = getStatsData(StatisticsType.TICKET_SALES);
    return (sData?.data?.online ?? 0) + (sData?.data?.onSite ?? 0);
  };

  useEffect(() => {
    if (location) {
      dispatch(fetchStatistics(location));
    } else if (mainLocation) {
      dispatch(fetchStatistics(mainLocation.uuid));
    }
  }, [location, mainLocation]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid xs={12} xl={6} item>
          <StatisticsBox stats={getStatsData(StatisticsType.CURRENT_CAPACITY)} name={getStatsLocationName()} statType={StatBoxType.Today}></StatisticsBox>
        </Grid>
        <Grid xs={12} xl={6} item>
          <StatisticsBox stats={getStatsData(StatisticsType.WHITE_LIST_TRAFFIC)} name={getStatsLocationName()} statType={StatBoxType.WhiteList}></StatisticsBox>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              border: '1px solid #E2E8F0',
              boxShadow: '10px 5px 10px #EFEFEF',
              borderRadius: '10px',
            }}
          >
            <Grid container>
              <Grid xs={12} item>
                <Typography sx={{ fontWeight: '800', fontSize: '1.71rem', mb: 1 }}>Bilety parkingowe</Typography>
              </Grid>
              <Grid xs={12} lg={5} item>
                <Stack direction="row" spacing={1}>
                  <Typography sx={{ fontWeight: '600', fontSize: '1.29rem', mb: 1 }}>Sprzedano dzisiaj:</Typography>
                  <Typography sx={{ fontWeight: '800', fontSize: '1.29rem', mb: 1 }}>{(getSalesData()?.data?.online ?? 0) + (getSalesData()?.data?.onSite ?? 0)} szt.</Typography>
                </Stack>
              </Grid>
              <Grid xs={12} lg={7} item>
                <Stack direction="row" spacing={1}>
                  <Typography sx={{ fontWeight: '600', fontSize: '1.29rem', mb: 1 }}>Wykorzystano na dzisiaj:</Typography>
                  <Typography sx={{ fontWeight: '800', fontSize: '1.29rem', mb: 1 }}>{getSalesData()?.data?.onlineUsed ?? 0} szt.</Typography>
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={{ xs: 1 }}>
              <Grid xs={12} md={6} item>
                <TicketChart salesType={SalesType.Online} value={getSalesData()?.data?.online} sum={getSalesSum()}></TicketChart>
              </Grid>
              <Grid xs={12} md={6} item>
                <TicketChart salesType={SalesType.Onsite} value={getSalesData()?.data?.onSite} sum={getSalesSum()}></TicketChart>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
