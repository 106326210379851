import { combineReducers } from '@reduxjs/toolkit';
import userReducer from '../modules/user/user-slice';
import locationReducer from '../modules/location/slice';
import whiteListReducer from '../modules/white-list/slice';
import trafficAnalysisReducer from '../modules/traffic-analisys/slice';
import barriersReducer from '../modules/barriers/slice';
import layoutReducer from '../modules/layout/slice';
import authReducer from '../modules/auth/slice';

const rootReducer = combineReducers({
  user: userReducer,
  whiteList: whiteListReducer,
  location: locationReducer,
  trafficAnalysis: trafficAnalysisReducer,
  barriers: barriersReducer,
  layout: layoutReducer,
  auth: authReducer
});

export default rootReducer;
