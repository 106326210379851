import Modules from "../modules/";
import Partials from "./cameras/";

const Cameras = () => {

    const useCameras = Modules.useCameras();

    return (
        <>
            <Partials.Filters {...useCameras} />
            <Partials.Monitoring {...useCameras} />
        </>
    );
}

export default Cameras;