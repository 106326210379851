import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const MapHeader = () => {


    return (
        <FormControl sx={{m: 1, width: '100vw'}} variant="outlined">
            <FormLabel>Mapa</FormLabel>
        </FormControl>
    );
}

export default MapHeader;