import ToggleButton from '@mui/material/ToggleButton';
import SearchIcon from '@mui/icons-material/Search';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';

const MapZoom = ({onClickZoomInit, onClickZoomIn, onClickZoomOut}) => {

    return (
            <ToggleButtonGroup
                sx={{position:'fixed', top:'130px', right:'2vw', backgroundColor:'white'}}
                orientation="vertical"
                exclusive
            >
                <ToggleButton value="zoom" onClick={onClickZoomInit}>
                    <Tooltip title="Rozmiar domyślny">
                    <SearchIcon />
                    </Tooltip>
                </ToggleButton>
                <ToggleButton value="zoomIn" onClick={onClickZoomIn}>
                    <Tooltip title="Powiększ mapę">
                    <AddIcon />
                    </Tooltip>
                </ToggleButton>
                <ToggleButton value="zoomOut" onClick={onClickZoomOut}>
                    <Tooltip title="Pomniejsz mapę">
                    <RemoveIcon />
                    </Tooltip>
                </ToggleButton>
            </ToggleButtonGroup>
    );
}

export default MapZoom;