import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Stack, Button, TableFooter, Typography, Snackbar, Alert } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { getBarriers } from '../../modules/barriers/selectors';
import { ActionType, Barrier, SendActionData } from '../../modules/barriers/models';
import { getSelectedHeadLocationUuid } from '../../modules/location/selector';
import ScheduleDialog from './ScheduleDialog';
import { sendActionBarrier } from '../../modules/barriers/operations';
import { useState } from 'react';
import moment from 'moment';

const DataGrid = () => {
  const dispatch = useAppDispatch();

  const [openScheduleDialog, setOpenScheduleDialog] = useState<boolean>(false);
  const [selectedBarrier, setSelectedBarrier] = useState<Barrier>();

  const rows = useAppSelector((s) => getBarriers(s.barriers));
  const locationUuid = useAppSelector((s) => getSelectedHeadLocationUuid(s.location));

  const handleSendActionBarrier = async (uuid: string, actionType: ActionType) => {
    const sendActionData = { locationUuid: locationUuid, barrierUuid: uuid, actionType: actionType } as SendActionData;
    await dispatch(sendActionBarrier(sendActionData));
  };
  const handleSchedule = (barrier: Barrier) => {
    setSelectedBarrier(barrier);
    setOpenScheduleDialog(true);
  };

  const isOpen = (barier: Barrier): boolean => {
    if (barier.openingTime == barier.closingTime) {
      return false;
    }
    return moment(new Date()).isBetween(barier.openingTime, barier.closingTime);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ width: '100%' }} aria-label="Zarządzanie szlabanami">
          <TableHead>
            <TableRow
              sx={{
                '& th': {
                  fontWeight: 800,
                },
              }}
            >
              <TableCell>Miejsce</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Operacje</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <TableRow key={row.uuid} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <Typography sx={{ fontSize: '1.14rem', fontWeight: 800 }}>{row.name}</Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  {isOpen(row) ? <Typography color="#00C4AC">otwarte</Typography> : <Typography color="#F2664F">zamknięte</Typography>}
                </TableCell>
                <TableCell>
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleSendActionBarrier(row.uuid, ActionType.OPEN)}
                      sx={{ backgroundColor: '#00C4AC', '&:hover': { backgroundColor: '#008D78' } }}
                    >
                      Otwórz szlaban
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleSendActionBarrier(row.uuid, ActionType.OPEN)}
                      sx={{ backgroundColor: '#F2664F', '&:hover': { backgroundColor: '#B32E21' } }}
                    >
                      Zamknij szlaban
                    </Button>
                    <Button variant="contained" color="primary" size="small" onClick={() => handleSchedule(row)} sx={{ backgroundColor: '#1891E5', '&:hover': { backgroundColor: '#005FAD' } }}>
                      Zaplanuj otwarcie/zamkniecie
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ScheduleDialog open={openScheduleDialog} onClose={() => setOpenScheduleDialog(false)} barrier={selectedBarrier}></ScheduleDialog>
    </>
  );
};

export default DataGrid;
