import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InfoBoxModel, LayoutStateModel } from './models';

const initialState = {
  infoBoxData: { open: false } as InfoBoxModel,
} as LayoutStateModel;

const layoutSlice = createSlice({
  name: 'layout',
  initialState: initialState,
  reducers: {
    openInfoBox(state, action: PayloadAction<string>) {
      state.infoBoxData = {
        message: action.payload,
        open: true,
      } as InfoBoxModel;
    },
    closeInfoBox(state) {
      state.infoBoxData = {
        // message: '',
        open: false,
      } as InfoBoxModel;
    },
  },
});

export const { openInfoBox, closeInfoBox } = layoutSlice.actions;
export default layoutSlice.reducer;
