import { Feature } from '../location/model';

export interface UserStateModel {
  userModel: UserModel;
  userProfile: UserProfile;
}

export interface UserModel {
  email: string;
  role: string;
  token: string;
  enabledFeatures: Feature[];
}

export enum IconType {
  Mountain,
  Map,
  Camera,
  Car,
  Traffic,
  Management,
}

export interface UserProfile {
  enabledFeatures: Feature[];
}
