import { stat } from 'fs';
import { Barrier, BarriersStateModel } from './models';

export const getBarriers = (state: BarriersStateModel): Barrier[] => {
  return state.rows;
};

export const selectedLocationUuid = (state: BarriersStateModel): string => {
  return state.selectedLocation;
};
