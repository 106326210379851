import { useReducer, useEffect } from 'react';
import axiosInstance from '../../utils/axios';

const initialState = {
  URl_STRUCTURE: 'v1/location/structure',
  URL_CAMERAS: 'v1/location/{locationUuid}/cameras',
  structure: [],
  cameras: [],
  search: '',
  selectedCameras: [],
  applySelectedCameras: false,
};

const actions = {
  INIT_CAMERAS: 'INIT_CAMERAS',
  CHANGE_SEARCH_BY_NAME_CAMERAS: 'CHANGE_SEARCH_BY_NAME_CAMERAS',
  CLICK_APPLY_SELECT_CAMERAS: 'CLICK_APPLY_SELECT_CAMERAS',
  CHANGE_SELECTED_CAMERAS: 'CHANGE_SELECTED_CAMERAS',
  CLOSE_SELECT_CAMERAS: 'CLOSE_SELECT_CAMERAS',
};

const reducer = (state, action) => {
  const type = action.type;
  const payload = action.payload;

  switch (type) {
    case actions.INIT_CAMERAS:
      return {
        ...state,
        structure: payload.structure,
        cameras: payload.cameras,
      };

    case actions.CHANGE_SEARCH_BY_NAME_CAMERAS:
      return {
        ...state,
        selectedCameras:
          payload.search.length === 0
            ? []
            : state.cameras
                .filter((camera) => camera.name.indexOf(payload.search) > -1)
                .map((camera) => {
                  return camera.name;
                }),
        search: payload.search,
        applySelectedCameras: true,
      };

    case actions.CLICK_APPLY_SELECT_CAMERAS:
      return {
        ...state,
        search: '',
        applySelectedCameras: true,
      };

    case actions.CLOSE_SELECT_CAMERAS:
      return {
        ...state,
        search: '',
        applySelectedCameras: true,
      };

    case actions.CHANGE_SELECTED_CAMERAS:
      return {
        ...state,
        selectedCameras: payload.selectedCameras,
        search: '',
        applySelectedCameras: false,
      };

    default:
      throw new Error('useCameras reducer error - action not supported.');
  }
};

const useCameras = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initData = async () => {
      const structure = await axiosInstance.get(state.URl_STRUCTURE);
      const structureData = structure.data;

      const [firstElement] = structure.data; //root element of structure

      const cameras = await axiosInstance.get(state.URL_CAMERAS.replace('{locationUuid}', firstElement.uuid));
      const camerasData = cameras.data;

      dispatch({ type: actions.INIT_CAMERAS, payload: { structure: structureData, cameras: camerasData } });
    };
    initData();
  }, []);

  const onClickApplySelectedCameras = (event) => {
    dispatch({ type: actions.CLICK_APPLY_SELECT_CAMERAS, payload: null });
  };

  const onChangeSelectedCameras = (event) => {
    dispatch({ type: actions.CHANGE_SELECTED_CAMERAS, payload: { selectedCameras: event.target.value } });
  };

  const onChangeSearchCameras = (event) => {
    dispatch({ type: actions.CHANGE_SEARCH_BY_NAME_CAMERAS, payload: { search: event.target.value } });
  };

  const onCloseSelectedCameras = () => {
    dispatch({ type: actions.CLOSE_SELECT_CAMERAS, payload: null });
  };

  return {
    ...state,
    onClickApplySelectedCameras,
    onChangeSelectedCameras,
    onChangeSearchCameras,
    onCloseSelectedCameras,
  };
};

export default useCameras;
