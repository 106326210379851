import {
  alpha,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  styled
} from '@mui/material';
import { useState } from 'react';
import { getMainLocation, getSubLocations } from '../../modules/location/selector';
import { DataGridFilters } from '../../modules/traffic-analisys/models';
import { fetchTrafficAnalisysRows } from '../../modules/traffic-analisys/operations';
import { getFilters } from '../../modules/traffic-analisys/selectors';
import { setFilters } from '../../modules/traffic-analisys/slice';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const Filters = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector((s) => getFilters(s.trafficAnalysis));
  const mainLocation = useAppSelector((state) => getMainLocation(state.location));
  const allSubLocations = useAppSelector((state) => getSubLocations(state.location, mainLocation?.uuid));

  const [selectedLocationLocal, setSelectedLocationLocal] = useState<string>(allSubLocations[0]?.uuid);
  const [searchText, setSearchText] = useState<string>('');
  const [sortOrder, setSortOder] = useState<string>('desc');
  const [sortBy, setSortBy] = useState<string>('eventTimestamp');

  const handleLocationChange = (event: SelectChangeEvent) => {
    setSelectedLocationLocal(event.target.value);
    const newFilters = { ...filters, locationId: event.target.value, pageNo: 0 } as DataGridFilters;

    dispatch(fetchTrafficAnalisysRows(newFilters));
    dispatch(setFilters(newFilters));
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    const newFilters = { ...filters, searchText: event.target.value, pageNo: 0 } as DataGridFilters;

    refreshGrid(newFilters);
  };

  const handleSortByChange = (event: SelectChangeEvent) => {
    setSortBy(event.target.value);
    const newFilters = { ...filters, sortBy: event.target.value, pageNo: 0 } as DataGridFilters;

    refreshGrid(newFilters);
  };

  const handleSortOrderChange = (sortOrder: string) => {
    setSortOder(sortOrder);
    const newFilters = { ...filters, sortOrder: sortOrder, pageNo: 0 } as DataGridFilters;
    refreshGrid(newFilters);
  };

  const refreshGrid = (newFilters: DataGridFilters) => {
    dispatch(fetchTrafficAnalisysRows(newFilters));
    dispatch(setFilters(newFilters));
  };

  return (
    <>
      <Grid container>
        <Grid item xs={4}>
          <Select inputProps={{ 'aria-label': 'Without label' }} value={selectedLocationLocal}
                  onChange={handleLocationChange} sx={{ width: '100%' }}>
            <MenuItem value={'ALL'}>Wszystkie</MenuItem>
            {allSubLocations.map((l) => (
              <MenuItem key={l.uuid} value={l.uuid}>
                {l.name.replace('Parking ', '').replace('Wjazd na ', '')}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder='Szukaj...'
              inputProps={{ 'aria-label': 'search' }}
              sx={{ border: '#E2E8F0 solid 1px', borderRadius: '4px', height: 56 }}
              onChange={handleSearchChange}
              value={searchText}
            />
          </Search>
        </Grid>
        <Grid item xs={4}>
          <Stack direction='row' spacing={1}>
            <FormControl fullWidth>
              <InputLabel id='sort-select-label'>Sortowanie</InputLabel>
              <Select value={sortBy} onChange={handleSortByChange} labelId='sort-select-label' label='Sortowanie'>
                <MenuItem value='vehiclePlates'>Numer rej</MenuItem>
                <MenuItem value='eventType'>Zdarzenie</MenuItem>
                <MenuItem value='eventTimestamp'>Data zdarzenia</MenuItem>
                <MenuItem value='ticketPaymentTimestamp'>Data płatności</MenuItem>
                <MenuItem value='ticketCreationTimestamp'>Data zakupu</MenuItem>
                <MenuItem value='ticketId'>Numer biletu</MenuItem>
                <MenuItem value='ticketPaymentLocationName'>Miejsce zakupu</MenuItem>
                <MenuItem value='ticketTypeName'>Rodzaj biletu</MenuItem>
                <MenuItem value='ticketPrice'>Cena</MenuItem>
              </Select>
            </FormControl>
            <FormControl></FormControl>
            <ButtonGroup aria-label='outlined primary button group'>
              <Button size='small' variant={sortOrder === 'asc' ? 'contained' : 'outlined'}
                      onClick={() => handleSortOrderChange('asc')}>
                <ArrowDownwardIcon></ArrowDownwardIcon>
              </Button>
              <Button size='small' variant={sortOrder === 'desc' ? 'contained' : 'outlined'}
                      onClick={() => handleSortOrderChange('desc')}>
                <ArrowUpwardIcon></ArrowUpwardIcon>
              </Button>
            </ButtonGroup>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch'
    }
  }
}));

export default Filters;
