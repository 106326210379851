import { useLocation } from 'react-router';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../../utils/hooks';
import { getIsAuthenticated } from '../../modules/auth/selector';

const AuthRoutes = () => {
  const location = useLocation();

  const isAuthenticated: boolean = useAppSelector((state) => getIsAuthenticated(state.auth));

  console.log(isAuthenticated);

  return isAuthenticated ? <Navigate to='/' replace state={{ from: location }} /> : <Outlet />;
};

export default AuthRoutes;
