import * as React from 'react';
import TablePagination from '@mui/material/TablePagination';

interface PaginationProps {
  totalRows: number;
  pageNo: number;
  rowsPerPage: number;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newRowsPerPage: number) => void;
}

const Pagination = ({ totalRows, pageNo, rowsPerPage, onPageChange, onPageSizeChange }: PaginationProps) => {
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onPageSizeChange(Number.parseInt(event.target.value));
  };

  return (
    <TablePagination
      component="div"
      count={totalRows}
      page={pageNo}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelDisplayedRows={({ from, to, count }) => {
        return '' + from + '-' + to + ' z ' + count;
      }}
      labelRowsPerPage="Liczba wierszy na stronie: "
      sx={{
        '& .MuiToolbar-root': {
          backgroundColor: 'inherit',
        },
      }}
    />
  );
};

export default Pagination;
