import { Grid, Typography } from "@mui/material";
import DataGrid from "./barriers/DataGrid";
import Filters from "./barriers/Filters";

const Barriers = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <h2>Zarządzanie szlabanami</h2>
        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Filters></Filters>
        </Grid>
        <Grid item xs={12}>
          <DataGrid />
        </Grid>
      </Grid>
    </>
  );
};

export default Barriers;
