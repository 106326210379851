import { DataGridFilters, TrafficAnalisysRow, TrafficAnalisysStateModel } from './models';

export const getTrafficAnalisysRows = (state: TrafficAnalisysStateModel): TrafficAnalisysRow[] => {
  return state?.data?.content;
};

export const getFilters = (state: TrafficAnalisysStateModel): DataGridFilters => {
  return state.filters;
};

export const isLoading = (state: TrafficAnalisysStateModel): boolean => {
  return state.isLoading;
};
