import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { openInfoBox } from '../layout/slice';
import { Barrier, ScheduleData, SendActionData } from './models';

export const fetchBarrierRows = createAsyncThunk('barriers/fetch', async (locationUuid: string): Promise<Barrier[]> => {
  try {
    console.log('fetch barriers ' + locationUuid);
    const rows = await axiosInstance.get(`/v1/location/${locationUuid}/barriers`);
    return rows.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export const sendActionBarrier = createAsyncThunk('barriers/open', async (data: SendActionData, thunkAPI): Promise<boolean> => {
  try {
    await axiosInstance.put(`/v1/location/${data.locationUuid}/barriers/${data.barrierUuid}/send-action?action=${data.actionType}`);
    thunkAPI.dispatch(openInfoBox('Komenda została wysłana.'));
    return true;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export const scheduleBarrierOpening = createAsyncThunk('barriers/schedule', async (data: ScheduleData, thunkAPI): Promise<boolean> => {
  try {
    const body = JSON.stringify({
      openingTime: data.fromTime,
      closingTime: data.toTime,
    });
    await axiosInstance.put(`/v1/location/${data.locationUuid}/barriers/${data.barrierUuid}/schedule`, body);
    thunkAPI.dispatch(openInfoBox('Zapis zakończony sukcesem'));
    return true;
  } catch (error) {
    console.error(error);
    throw error;
  }
});
