import { Box, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import ChartRow from './ChartRow';
import { StatBoxType, Statistics } from '../../modules/location/model';
import NumberCircularProgress from './CircularProgressionBox';

interface CurrentStatisticsBoxProps {
  stats?: Statistics;
  name: string;
  statType: StatBoxType;
}

const StatisticsBox = ({ stats, name, statType }: CurrentStatisticsBoxProps) => {
  return (
    <Paper sx={{ borderRadius: '10px', border: '1px solid #E2E8F0', boxShadow: '10px 5px 10px #EFEFEF', padding: '16px 24px', flexGrow: '1' }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography sx={{ fontFamily: 'Inter', fontWeight: '700', fontSize: '1.28rem' }}>{getTitle(statType, name)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={1}>
            <Typography sx={{ fontWeight: '600', fontSize: '0.85rem', color: '#718096' }}>Parkingi - </Typography>
            <Typography sx={{ fontWeight: '600', fontSize: '0.85rem' }}>{name}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Stack direction="row">
            <Box sx={{ height: 192 }}>
              <NumberCircularProgress value={getPerc(stats?.data?.takenSpots ?? 0, stats?.data?.availableSpots ?? 0)} type={statType} />
            </Box>
            <Divider orientation="vertical" color="#718096" flexItem sx={{ mx: 2 }}></Divider>
            <Stack direction="column" sx={{ justifyContent: 'center' }}>
              <ChartRow value={ChartRow1Value(statType, stats)} content={ChartRow1Title(statType)} statType={statType}></ChartRow>
              {statType != StatBoxType.WhiteList ? <ChartRow value={stats?.data?.multiDayTickets ?? 0} content="miejsca zajęte na pobyt wielodniowy:" statType={statType}></ChartRow> : null}
              <ChartRow value={ChartRow2Value(statType, stats)} content={ChartRow2Title(statType)} isDark={true} statType={statType}></ChartRow>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

const getPerc = (one?: number, two?: number): number => {
  if (one && two) {
    if (one === 0 || two === 0) {
      return 0;
    }
    if (one > two) {
      return 100;
    }
    return Math.ceil((one / two) * 100);
  }
  return 0;
};

const ChartRow1Title = (type: StatBoxType) => {
  switch (type) {
    case StatBoxType.WhiteList:
      return 'wjazdy:';
    default:
      return 'na parkingach obecnie jest samochodów:';
  }
};
const ChartRow2Title = (type: StatBoxType) => {
  switch (type) {
    case StatBoxType.WhiteList:
      return 'wyjazdy:';
    default:
      return 'wolne miejsca:';
  }
};

const ChartRow1Value = (type: StatBoxType, data: Statistics | undefined): number => {
  switch (type) {
    case StatBoxType.WhiteList:
      return data?.data?.exists ?? 0;
    default:
      return data?.data?.takenSpots ?? 0;
  }
};

const ChartRow2Value = (type: StatBoxType, data: Statistics | undefined): number => {
  switch (type) {
    case StatBoxType.WhiteList:
      return data?.data?.entries ?? 0;
    default:
      return data?.data?.availableSpots ?? 0;
  }
};

const getTitle = (type: StatBoxType, name: string) => {
  switch (type) {
    case StatBoxType.Today:
      return 'Wyjazdy dzisiaj';
    case StatBoxType.WhiteList:
      return 'Biała lista';
    default:
      return name;
  }
};

export default StatisticsBox;
