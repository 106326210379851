import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VehicleType, WhiteListRequest, WhiteListResponse, WhiteListStateModel } from './models';
import { deleteWhiteListEntry, fetchVehicleTypes, fetchWhiteListRows } from './operations';
import {DataGridFilters} from "../traffic-analisys/models";

const initialState = {
  data: {} as WhiteListResponse,
  vehicleTypes: [] as VehicleType[],
  openDialog: false,
  filters: { pageSize: 10, page: 0 },

} as WhiteListStateModel;

const whiteListSlice = createSlice({
  name: 'white-list',
  initialState: initialState,
  reducers: {
    setFilters(state, action: PayloadAction<WhiteListRequest>) {
      state.filters = action.payload;
    },
    setSelectedEntry: (state, action) => {
      state.entry = action.payload;
    },
    openDialog: (state) => {
      state.openDialog = true;
    },
    closeDialog: (state) => {
      state.openDialog = false;
      state.entry = undefined;
    },
    setLocation: (state, action: PayloadAction<string>) => {
      state.filters.locationUuidFilter = action.payload;
    },
    setMainLocation: (state, action: PayloadAction<string>) => {
      state.filters.mainLocationId = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchWhiteListRows.fulfilled, (state, action: PayloadAction<WhiteListResponse>) => {
      state.data = action.payload;
    });
    builder.addCase(fetchVehicleTypes.fulfilled, (state, action: PayloadAction<VehicleType[]>) => {
      state.vehicleTypes = action.payload;
    });
    builder.addCase(deleteWhiteListEntry.fulfilled, (state, action: PayloadAction<string>) => {
      const index = state.data.content.findIndex((x) => x.uuid == action.payload);
      state.data.content.splice(index, 1);
    });
  },
});

export const { setSelectedEntry, openDialog, closeDialog, setFilters, setLocation, setMainLocation } = whiteListSlice.actions;

export default whiteListSlice.reducer;
