import { useRef, useState, useEffect } from 'react';
import Hls from 'hls.js';

const VideoPlayer = ({ videoUrl }) => {
  const [hls, setHls] = useState(new Hls());
  const videoEl = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoEl.current) {
      hls.attachMedia(videoEl.current);
      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        hls.loadSource(videoUrl);
      });
      videoEl.current.play();
    }
  }, []);

  return <video ref={videoEl} controls muted autoPlay style={{ width: '100%', height: 'auto', minHeight: '50%' }} />;
};

export default VideoPlayer;
