import { Button, Stack, TextField } from '@mui/material';
import { Link, useSearchParams } from 'react-router-dom';
import AuthHeaderBox from './login/AuthHeader';
import { checkToken, passwordReset } from '../modules/user/operations';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const PasswordReminder = () => {
  let [searchParams] = useSearchParams();
  const [enableButton, setEnableButton] = useState<boolean>(true);
  useEffect(() => {
    const token = searchParams.get('token');
    console.log(token);
    if (token) {
      const checkTokenInner = async () => {
        const result = await checkToken(token);
        console.log(result);
        setEnableButton(result);
      };

      checkTokenInner();
      return;
    }
    setEnableButton(false);
  }, []);

  useEffect(() => {
    if (!enableButton) {
      toast.error('Nieprawodłowy token', { autoClose: false });
    }
  }, [enableButton]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const token = searchParams.get('token');

    if (token) {
      if (event.target.password.value != event.target.password2.value) {
        toast.error('Wprowadzone hasła nie zgadzają się.');
        return;
      }
      if (event.target.password.value.length < 5) {
        toast.error('Wprowadzone hasło powinno zawierać minimum 5 znaków.');
        return;
      }
      await passwordReset(event.target.password.value, token);
      event.target.password.value = '';
      event.target.password2.value = '';
    }
  };

  return (
    <Stack
      sx={{
        marginTop: 8
      }}
      component='form'
      onSubmit={handleSubmit}
      noValidate
      spacing={2}
      direction={'column'}
      alignItems={'center'}
    >
      <AuthHeaderBox
        message='Wpisz poniżej nowe hasło, które od tej pory będzie służyło do logowania się do strony. (minimum 5 znaków)' />
      <TextField fullWidth id='password' label='Hasło' name='password' autoComplete='current-password'
                 type='password' inputProps={{ minLength: 4 }} />
      <TextField fullWidth id='password2' label='Potwierdź hasło' name='password2'
                 autoComplete='current-password' type='password' inputProps={{ minLength: 4 }} />
      <Stack direction={'row'} spacing={1}><Button component={Link} to='/login' type='submit' variant='outlined'
                                                   sx={{ width: '200px', height: '72px' }}>
        Wróć do logowania
      </Button><Button type='submit' variant='contained'
                       sx={{ width: '200px', height: '72px' }} disabled={!enableButton}>
        Ustaw nowe hasło
      </Button></Stack>

    </Stack>
  );
};

export default PasswordReminder;
