import { createTheme, ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: 'Manrope',
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        regular: {
          backgroundColor: 'white',
        },
      },
      // styleOverrides: {
      //   containedPrimary: {
      //     backgroundColor: '#6868AC',
      //   },
      // },
      // variants: [
      //   {
      //     props: { color: 'primary' },
      //     style: {
      //       backgroundColor: '#6868AC',
      //       '&:hoover': {
      //         backgroundColor: '#484878',
      //       },
      //     },
      //   },
      // ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#6868AC',
    },
  },
};

const theme = createTheme(themeOptions);

export default theme;
