import { Grid } from '@mui/material';
import VideoPlayer from './VideoPlayer';

const Monitoring = ({ applySelectedCameras, cameras, selectedCameras }) => {
  return (
    <Grid container spacing={1}>
      {applySelectedCameras &&
        cameras.map((camera, i) =>
          selectedCameras.indexOf(camera.name) > -1 ? (
            <Grid item xs={selectedCameras.length === 1 ? 12 : 6}>
              <VideoPlayer key={i} videoUrl={camera.url} />
            </Grid>
          ) : null
        )}
    </Grid>
  );
};

export default Monitoring;
