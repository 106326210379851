import {Marker} from "react-map-gl";

const MapMarkers = ({structure}) => {

    const mainLocation = structure;
    const subLocations = mainLocation.subLocations;

    return (
        <>
            <Marker
                longitude={mainLocation.geoLocalisation.lon}
                latitude={mainLocation.geoLocalisation.lat}
                color="red">
            </Marker>
            {subLocations.map((subLocation) => (
                <Marker
                    longitude={subLocation.geoLocalisation.lon}
                    latitude={subLocation.geoLocalisation.lat}
                    color="blue">
                </Marker>
            ))}
        </>
    );
}

export default MapMarkers;