import { Grid, Typography } from '@mui/material';

const User = () => {
    return <><Grid container>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography component="h5" sx={{ fontWeight: 800, fontSize: '1.43rem' }}>
            Konto
          </Typography>
        </Grid>
      </Grid></>
}

export default User;