import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import CottageIcon from '@mui/icons-material/Cottage';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import SnowshoeingOutlinedIcon from '@mui/icons-material/SnowshoeingOutlined';
import {
    EntranceIcon,
    ParkingIcon,
    PedestrianEntranceIcon,
    MountainHostelIcon,
    CamerasIcon
} from "../../components/Icons";

const MapLegend = () => {


    return (
                <List sx={{position:'fixed', bottom:'6vh', right:'2vw', backgroundColor:'white'}}>
                    <ListItem>
                        <ListItemText primary="Legenda" />
                    </ListItem>
                    <Divider/>
                    <ListItem>
                        <ListItemIcon>{ParkingIcon}</ListItemIcon>
                        <ListItemText primary="Parking" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>{EntranceIcon}</ListItemIcon>
                        <ListItemText primary="Wjazd dla samochodów" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>{PedestrianEntranceIcon}</ListItemIcon>
                        <ListItemText primary="Wejście dla pieszych" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>{MountainHostelIcon}</ListItemIcon>
                        <ListItemText primary="Schronisko Górskie" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>{CamerasIcon}</ListItemIcon>
                        <ListItemText primary="Kamery" />
                    </ListItem>
                </List>
    );
}

export default MapLegend;