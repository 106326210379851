import InputLabel from '@mui/material/InputLabel';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import { useRef, useState } from 'react';

const Filters = ({ cameras, selectedCameras, onClickApplySelectedCameras, onChangeSelectedCameras, onChangeSearchCameras, onCloseSelectedCameras }) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleFilterButtonClick = (e) => {
    onClickApplySelectedCameras(e);
    setOpen(false);
  };
  const handleClose = () => {
    onCloseSelectedCameras();
    setOpen(false);
  };

  return (
    <>
      <h2>Kamery:</h2>
      <FormControl sx={{ m: 1, width: '50vw' }} variant="outlined">
        <InputLabel htmlFor="select">Wybierz kamerę</InputLabel>
        <Select
          id="selected-cameras"
          multiple
          open={open}
          label="Wybierz kamerę"
          onOpen={() => setOpen(true)}
          onClose={handleClose}
          onChange={onChangeSelectedCameras}
          value={selectedCameras}
          renderValue={(selected) => selected.join(', ')}
        >
          {cameras.map((camera) => (
            <MenuItem key={camera.name} value={camera.name}>
              <Checkbox checked={selectedCameras.indexOf(camera.name) > -1} />
              <ListItemText primary={camera.name} />
            </MenuItem>
          ))}
          <MenuItem key="button">
            <Button
              variant="contained"
              onClick={handleFilterButtonClick}
              style={{
                maxWidth: '27vw',
                minWidth: '27vw',
              }}
            >
              Zastosuj
            </Button>
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default Filters;
