import MapHeader from "./MapHeader";
import MapZoom from "./MapZoom";
import MapLegend from "./MapLegend";
import MapView from "./MapView";
import MapMarkers from "./MapMarkers";

export default {
    MapView,
    MapHeader,
    MapZoom,
    MapLegend,
    MapMarkers
}