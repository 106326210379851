import { useLocation } from 'react-router';
import { Navigate, Outlet } from 'react-router-dom';
import { getIsAuthenticated } from '../../modules/auth/selector';
import { useAppSelector } from '../../utils/hooks';

const ProtectedRoutes = () => {
  const location = useLocation();
  const getUrl = (): string => {
    return `/login?r=${location.pathname}`;
  };
  const isAuthenticated: boolean = useAppSelector((state) => getIsAuthenticated(state.auth));

  return isAuthenticated ? <Outlet /> : <Navigate to={getUrl()} replace state={{ from: location }} />;
};

export default ProtectedRoutes;
