import {
  alpha,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  InputBase,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { getMainLocation, getSubLocations } from '../../modules/location/selector';
import { getFilters, getVehicleTypes } from '../../modules/white-list/selectors';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import SearchIcon from '@mui/icons-material/Search';
import { openDialog, setFilters } from '../../modules/white-list/slice';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import plLocale from 'date-fns/locale/pl';
import { WhiteListRequest } from '../../modules/white-list/models';
import { fetchWhiteListRows } from '../../modules/white-list/operations';

const allDays = ['pn', 'wt', 'śr', 'czw', 'pt', 'sob', 'nd'];

interface FiltersProps {
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch'
    }
  }
}));

const Filters = ({}: FiltersProps) => {
  const dispatch = useAppDispatch();

  const allVehicleTypes = useAppSelector((state) => getVehicleTypes(state.whiteList));
  const mainLocation = useAppSelector((state) => getMainLocation(state.location));
  const allSubLocations = useAppSelector((state) => getSubLocations(state.location, mainLocation?.uuid));
  const filters = useAppSelector((state) => getFilters(state.whiteList));

  const [days, setDays] = useState<string[]>(allDays);
  const [vehicleTypes, setVehicleTypes] = useState<string[]>([]);
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [searchText, setSearchText] = useState<string>('');

  const handleChange = (event: SelectChangeEvent<typeof days>) => {
    const {
      target: { value }
    } = event;
    setDays(typeof value === 'string' ? value.split(',') : value);
  };

  const handleVtChange = (event: SelectChangeEvent<typeof vehicleTypes>) => {
    const {
      target: { value, name }
    } = event;
    setVehicleTypes(typeof value === 'string' ? value.split(',') : value);
  };

  const handleAddNewEntry = () => {
    dispatch(openDialog());
  };

  const handleLocationChange = async (event: SelectChangeEvent) => {
    const newFilters = {
      ...filters,
      locationUuidFilter: event.target.value,
      page: 0
    } as WhiteListRequest;

    await fetchRows(newFilters);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    const newFilters = {
      ...filters,
      platesFilter: event.target.value,
      page: 0
    } as WhiteListRequest;
    fetchRows(newFilters);
  };

  const fetchRows = async (newFilters: WhiteListRequest) => {
    dispatch(setFilters(newFilters));
    await dispatch(fetchWhiteListRows(newFilters));
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={plLocale}>
        <Grid container sx={{ mb: 1 }}>
          <Grid item xs={4} sx={{ pr: 1 }}>
            <FormControl sx={{ width: '100%' }} variant='outlined'>
              <Select id='selected-location' onChange={handleLocationChange} value={filters?.locationUuidFilter ?? ''}>
                {allSubLocations.map((l) => (
                  <MenuItem key={l.uuid} value={l.uuid}>
                    <ListItemText primary={l.name.replace('Parking ', '').replace('Wjazd na ', '')} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl sx={{ width: '100%' }} variant='outlined'>
              <InputLabel htmlFor='outlined-adornment-search'>Szukaj...</InputLabel>
              <OutlinedInput
                value={searchText}
                id='outlined-adornment-search'
                onChange={handleSearchChange}
                type='text'
                endAdornment={
                  <InputAdornment position='end'>
                    <SearchIcon />
                  </InputAdornment>
                }
                label='Szukaj'
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={3}>
            <Button
              onClick={handleAddNewEntry}
              sx={{
                backgroundColor: '#6868AC',
                color: 'white',
                p: 2,
                borderRadius: '12px',
                '&:hover': {
                  backgroundColor: '#5d5d9a'
                }
              }}
            >
              Dodaj nowy pojazd
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </>
  );

  const back = () => {
    return (
      <Grid container sx={{ mb: 1 }}>
        <Grid item xs={3}>
          <Stack direction='column'>
            <Typography>Data</Typography>
            <Stack direction='row'>
              <DatePicker
                label='Od'
                value={dateFrom}
                onChange={(newValue) => {
                  setDateFrom(newValue);
                }}
              ></DatePicker>
              <DatePicker
                label='Do'
                value={dateTo}
                onChange={(newValue) => {
                  setDateTo(newValue);
                }}
              ></DatePicker>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack direction='column'>
            <Typography>Dni</Typography>
            <Select displayEmpty inputProps={{ 'aria-label': 'Without label' }} multiple value={days}
                    onChange={handleChange} renderValue={(selected) => selected.join(', ')}>
              {allDays.map((day) => (
                <MenuItem key={day} value={day}>
                  <Checkbox checked={days.indexOf(day) > -1} />
                  {/* {day} */}
                  <ListItemText primary={day} />
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack direction='column'>
            <Typography>Godziny</Typography>
            {/* <DatePicker></DatePicker> */}
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack direction='column'>
            <Typography>Rodzaj pojazdu</Typography>
            <Select
              value={vehicleTypes}
              onChange={handleVtChange}
              /*input={<OutlinedInput label="Tag" />}*/ renderValue={(selected) => {
              if (selected.length === 0) {
                return '';
              }
              return allVehicleTypes
                .filter((x) => selected[x.uuid] !== null)
                .map((x) => x.name)
                .join(', ');
            }}
              multiple
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {allVehicleTypes.map((vt) => (
                <MenuItem key={vt.uuid} value={vt.uuid}>
                  <Checkbox checked={vehicleTypes.indexOf(vt.uuid) > -1} />
                  <ListItemText primary={vt.name} />
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
      </Grid>
    );
  };
};

export default Filters;
