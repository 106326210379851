import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { openInfoBox } from '../layout/slice';
import { VehicleType, WhiteListRequest, WhiteListResponse, WhiteListRow } from './models';
import {DataGridFilters} from "../traffic-analisys/models";

export const fetchWhiteListRows = createAsyncThunk('white-list/fetch', async (wlRequest: WhiteListRequest): Promise<WhiteListResponse> => {
  try {
    const pageNum = Number(wlRequest.page) + 1;
    let queryParams = `?page=${pageNum}&pageSize=${wlRequest.pageSize}`;
    if (wlRequest.locationUuidFilter) {
      queryParams += '&locationUuidFilter=' + wlRequest.locationUuidFilter;
    }
    if (wlRequest.platesFilter && wlRequest.platesFilter.length > 0) {
      queryParams += '&platesFilter=' + wlRequest.platesFilter;
    }

    let url = `/v1/location/${wlRequest.mainLocationId}/white-list${queryParams}`;
    const rows = await axiosInstance.get(url);
    return rows.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export const fetchVehicleTypes = createAsyncThunk('white-list/vehicle-types/fetch', async (location: string): Promise<VehicleType[]> => {
  try {
    var rows = await axiosInstance.get<VehicleType[]>(`/v1/location/${location}/white-list/vehicle-types`);
    return rows.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const saveWhiteList = createAsyncThunk('white-list/save', async (entry: WhiteListRow, thunkAPI): Promise<boolean> => {
  console.log('save - white list entry');
  try {
    if (entry.uuid) {
      await axiosInstance.put<WhiteListRow>(`/v1/location/${entry.locationUuid}/white-list/${entry.uuid}`, entry);
    } else {
      await axiosInstance.post<WhiteListRow>(`/v1/location/${entry.locationUuid}/white-list`, entry);
    }
    thunkAPI.dispatch(openInfoBox('Wiersz został zaapisany.'));
    return true;
  } catch (error) {
    console.log(error);
  }
  return false;
});

export const deleteWhiteListEntry = createAsyncThunk('white-list/delete', async (entry: WhiteListRow, thunkAPI): Promise<string> => {
  console.log('delete - white list entry');
  try {
    await axiosInstance.delete(`/v1/location/${entry.locationUuid}/white-list/${entry.uuid}`);
    thunkAPI.dispatch(openInfoBox('Wiersz został usunięty.'));
    return entry.uuid;
  } catch (error) {
    console.log(error);
  }
  return '';
});

const createUrl = (endpoint: string, filters: WhiteListRequest) => {
  let url = `/v1/location/7aeb745f-9eb6-4a3b-8c5e-c269d647d40d/white-list/${endpoint}`;

  if (filters.locationUuidFilter && filters.locationUuidFilter !== 'ALL') {
    url += '?locationUuidFilter=' + filters.locationUuidFilter;
  }
  return url;
};

export const exportData = createAsyncThunk('white-list/export', async (filters:  WhiteListRequest): Promise<string> => {
  try {
    const result = await axiosInstance.get(createUrl('/export', filters));
    let csvContent = 'data:text/csv;charset=utf-8,' + result.data;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    const dateStr = new Date().toISOString();
    link.setAttribute('download', `biale_listy_${dateStr}.csv`);
    document.body.appendChild(link);
    link.click();
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
});
