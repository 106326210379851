import { Box, Button, Grid, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import AuthHeaderBox from './login/AuthHeader';
import { passwordReminder } from '../modules/user/operations';

const PasswordReminder = () => {
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    await passwordReminder(event.target.email.value);
    event.target.email.value = '';
  };

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
      component='form'
      onSubmit={handleSubmit}
      noValidate
    >
      <AuthHeaderBox message='Podaj swój adres email, a my wyślemy link do zresetowania hasła' />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField required fullWidth id='email' label='Adres email' name='email' autoComplete='email' />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            alignItems: 'center',
            flexDirection: 'column',
            display: 'flex'
          }}
        >
          <Button component={Link} to='/login' type='submit' variant='outlined' sx={{ width: '200px', height: '72px' }}>
            Wróć
          </Button>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            alignItems: 'center',
            flexDirection: 'column',
            display: 'flex'
          }}
        >
          <Button type='submit' variant='contained' sx={{ width: '200px', height: '72px' }}>
            Wyślij
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PasswordReminder;
