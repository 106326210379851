import { Backdrop, CircularProgress, Grid } from '@mui/material';
import { useEffect } from 'react';
import { fetchTrafficAnalisysRows } from '../modules/traffic-analisys/operations';
import { getFilters, isLoading } from '../modules/traffic-analisys/selectors';
import { useAppDispatch, useAppSelector } from '../utils/hooks';
import DataGrid from './trafficAnalisys/DataGrid';
import Filters from './trafficAnalisys/Filters';

const TrafficAnalisys = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector((s) => getFilters(s.trafficAnalysis));
  const loading = useAppSelector((s) => isLoading(s.trafficAnalysis));
  useEffect(() => {
    dispatch(fetchTrafficAnalisysRows(filters));
  }, []);
  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <h2>Analiza ruchu</h2>
        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Filters></Filters>
        </Grid>
        <Grid item xs={12}>
          <DataGrid />
        </Grid>
      </Grid>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  );
};

export default TrafficAnalisys;
