import { Paper, Stack, Typography } from '@mui/material';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { StatBoxType } from '../../modules/location/model';

interface ChartRowProps {
  value: number;
  content: string;
  isDark?: boolean;
  statType: StatBoxType;
}

const ChartRow = ({ value, content, isDark, statType }: ChartRowProps) => {
  return (
    <>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center', ml: 1, mt: 1 }}>
        <Paper sx={{ backgroundColor: getCarBgColor(statType), p: 1 }}>
          <DirectionsCarIcon sx={{ backgroundColor: getCarBgColor(statType), color: isDark === true ? '#EDF2F7' : 'white', opacity: isDark === true ? '0.2' : '1' }}></DirectionsCarIcon>
        </Paper>
        <FiberManualRecordIcon sx={{ fontSize: '0.6rem' }}></FiberManualRecordIcon>
        <Typography sx={{ fontWeight: 800 }}>{content}</Typography>
        <Typography sx={{ fontSize: '2rem', fontWeight: '700', fontFamily: 'Inter' }}>{value}</Typography>
      </Stack>
    </>
  );
};

const getCarBgColor = (type: StatBoxType) => {
  switch (type) {
    case StatBoxType.Today:
      return '#6668AA';
    case StatBoxType.WhiteList:
      return '#A6BBA6';
    case StatBoxType.ParkingBlue:
      return '#6A84D9';
    case StatBoxType.ParkingPink:
      return '#B871BF';
    default:
      return '';
  }
};
export default ChartRow;
