import {
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { DataGridFilters, EventType } from '../../modules/traffic-analisys/models';
import { getFilters, getTrafficAnalisysRows } from '../../modules/traffic-analisys/selectors';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import format from 'date-fns/format';
import PhotoDialog from './PhotoDialog';
import { useState } from 'react';
import Pagination from '../../components/Pagination';
import { setFilters } from '../../modules/traffic-analisys/slice';
import { exportData, fetchTrafficAnalisysRows } from '../../modules/traffic-analisys/operations';

import { VehicleTypeBus, VehicleTypeCar, VehicleTypeMotorcycle, VehicleTypeWheelChair } from '../../components/Icons';
import Checkbox from '@mui/material/Checkbox';

const DataGrid = () => {
  const dispatch = useAppDispatch();

  const [photoDialogOpen, setPhotoDialogOpen] = useState<boolean>(false);
  const [photoDialogUrl, setPhotoDialogUrl] = useState<string>('');
  const rows = useAppSelector((s) => getTrafficAnalisysRows(s.trafficAnalysis));
  const filters = useAppSelector((s) => getFilters(s.trafficAnalysis));

  const handleView = (url: string) => {
    setPhotoDialogUrl(url);
    setPhotoDialogOpen(true);
  };
  const handlePageChange = (newPage: number) => {
    const newFilters = { ...filters, pageNo: newPage } as DataGridFilters;
    refreshGrid(newFilters);
  };

  const handlePageSizeChange = (pageSize: number) => {
    const newFilters = { ...filters, pageSize: pageSize } as DataGridFilters;
    refreshGrid(newFilters);
  };

  const refreshGrid = (newFilters: DataGridFilters) => {
    dispatch(setFilters(newFilters));
    dispatch(fetchTrafficAnalisysRows(newFilters));
  };

  const handleExportClick = () => {
    dispatch(exportData(filters));
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          aria-label='Analiza ruchu'
          sx={{
            '& th': {
              fontWeight: 800
            }
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell><Checkbox /></TableCell>
              <TableCell>Numer rejestracyjny</TableCell>
              <TableCell>Zdarzenie</TableCell>
              <TableCell>Data i godzina</TableCell>
              <TableCell>Data zakupu biletu</TableCell>
              <TableCell>Płatność</TableCell>
              <TableCell>Rodzaj biletu</TableCell>
              <TableCell>Typ pojazdu</TableCell>
              <TableCell>Numer biletu</TableCell>
              <TableCell>Miejsce zakupu biletu</TableCell>
              <TableCell>Cena biletu</TableCell>
              <TableCell>Nieuprawniony wjazd</TableCell>
              <TableCell>Zdjęcie</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <TableRow key={row.uuid}>
                <TableCell><Checkbox /></TableCell>
                <TableCell component='th' scope='row'>
                  {row.vehiclePlates}
                </TableCell>
                <TableCell>{getEventTypeDescritpion(row.eventType)}</TableCell>
                <TableCell>
                  <Stack direction='column'>
                    <Typography>{format(new Date(row.eventTimestamp), 'dd.MM.yyyy')}</Typography>
                    <Typography>{format(new Date(row.eventTimestamp), 'HH.mm')}</Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  {row.ticketCreationTimestamp === null ? (
                    <></>
                  ) : (
                    <Stack direction='column'>
                      <Typography>{format(new Date(row.ticketCreationTimestamp), 'dd.MM.yyyy')}</Typography>
                      <Typography>{format(new Date(row.ticketCreationTimestamp), 'HH.mm')}</Typography>
                    </Stack>
                  )}
                </TableCell>
                <TableCell>
                  {row.ticketPaymentTimestamp === null ? (
                    <></>
                  ) : (
                    <Stack direction='column'>
                      <Typography>{format(new Date(row.ticketPaymentTimestamp), 'dd.MM.yyyy')}</Typography>
                      <Typography>{format(new Date(row.ticketPaymentTimestamp), 'HH.mm')}</Typography>
                    </Stack>
                  )}
                </TableCell>
                <TableCell>{row.ticketTypeName}</TableCell>
                <TableCell>{getVehicleTypeIconByTicketType(row.ticketTypeId)}</TableCell>
                <TableCell>{row.ticketId}</TableCell>
                <TableCell>{row.ticketPaymentLocationName}</TableCell>
                <TableCell>
                  {row.ticketPrice.amount} {row.ticketPrice.currency}
                </TableCell>
                <TableCell>Nie</TableCell>
                <TableCell>
                  <Stack direction='row'>
                    <Button variant='contained' color='primary' size='small' style={{ marginLeft: 16 }}
                            onClick={() => handleView(row.photoUrl)}>
                      Zobacz
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={12}>
                <Pagination
                  pageNo={filters.pageNo}
                  totalRows={filters.totalRows ?? 0}
                  rowsPerPage={filters.pageSize}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                ></Pagination>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Grid container sx={{ mt: 1, justifyContent: 'right' }}>
        <Button variant='contained' onClick={handleExportClick}>Eksportuj dane</Button>
      </Grid>

      <PhotoDialog open={photoDialogOpen} url={photoDialogUrl} onClose={() => setPhotoDialogOpen(false)}></PhotoDialog>
    </>
  );
};

//https://api.dev.tpn.bergregions.pl/api/internal/v1/traffic-analysis/ticket-types
const getVehicleTypeIconByTicketType = (ticketType: number): any => {
  switch (ticketType) {
    case 13861:
      return VehicleTypeBus();
    case 0:
      return VehicleTypeMotorcycle();
    case 13865:
      return VehicleTypeWheelChair();
    default:
      return VehicleTypeCar();
  }
};

const getEventTypeDescritpion = (eventType: EventType): string => {
  switch (eventType) {
    case EventType.ENTRY:
      return 'Wjazd';
    case EventType.ENTRY_ATTEMPT:
      return 'Próba wjazdu';
    case EventType.EXIT:
      return 'Wyjazd';
    default:
      return '';
  }
};

export default DataGrid;
