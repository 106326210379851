import { LocationStateModel, LocationVm, StatisticsLocation } from "./model";

//TODO: replace Location VM
export const getLocations = (state: LocationStateModel) => {
  return state.locations;
};

export const getMainLocation = (state: LocationStateModel): LocationVm => {
  return state.locations[0];
};

export const getSubLocations = (
  state: LocationStateModel,
  mainLocationUuid: string
) => {
  var subLocations = state.locations.find(
    (x) => x.uuid === mainLocationUuid
  )?.subLocations;
  if (subLocations) {
    return subLocations;
  }
  console.warn("Cannot find sublocations for id: " + mainLocationUuid);
  return [] as LocationVm[];
};

export const getSelectedHeadLocationUuid = (state: LocationStateModel) => {
  return state.selectedHeadLocationUuid;
};

export const getStatistics = (
  state: LocationStateModel
): StatisticsLocation[] | undefined => {
  return state.statistics;
};
