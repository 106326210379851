import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocationStateModel, LocationVm, StatisticsLocation } from './model';
import { fetchStatistics, fetchStructure } from './operations';

const initialState = {
  locations: [] as LocationVm[],
} as LocationStateModel;

const locationSlice = createSlice({
  name: 'location',
  initialState: initialState,
  reducers: {
    fetchedStructure(state, action: PayloadAction<LocationVm[]>) {
      state.locations = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchStructure.fulfilled, (state, action: PayloadAction<LocationVm[]>) => {
      state.locations = action.payload;
      if (state.locations.length > 0) {
        state.selectedHeadLocationUuid = state.locations[0].uuid;
      }
    });
    builder.addCase(fetchStatistics.fulfilled, (state, action: PayloadAction<StatisticsLocation[]>) => {
      console.log(action.payload);
      state.statistics = action.payload;
    });
  },
});

export const {} = locationSlice.actions;

export default locationSlice.reducer;
