export interface BarriersStateModel {
  rows: Barrier[];
  selectedLocation: string;
}
export interface Barrier {
  uuid: string;
  name: string;
  openingTime: string;
  closingTime: string;
}

export interface SendActionData {
  locationUuid: string;
  barrierUuid: string;
  actionType: ActionType;
}

export interface ScheduleData {
  locationUuid: string;
  barrierUuid: string;
  fromTime: string;
  toTime: string;
}

export enum ActionType {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
}
