export interface TrafficAnalisysStateModel {
  data: TrafficAnalisysData;
  filters: DataGridFilters;
  isLoading: boolean;
}

export interface TrafficAnalisysData {
  content: TrafficAnalisysRow[];
  page: number;
  pageSize: number;
  total: number;
}

export interface TrafficAnalisysRow {
  uuid: string;
  vehiclePlates: string;
  eventType: EventType;
  eventTimestamp: Date;
  ticketId: string;
  ticketCreationTimestamp: Date;
  ticketPaymentTimestamp: Date;
  ticketPaymentLocationUuid: string;
  ticketPaymentLocationName: string;
  ticketTypeId: number;
  ticketTypeName: string;
  ticketPrice: TicketPrice;
  photoUrl: string;
  whiteListEntry: boolean;
}

export enum EventType {
  ENTRY = 'ENTRY',
  ENTRY_ATTEMPT = 'ENTRY_ATTEMPT',
  EXIT = 'EXIT',
}

export interface TicketPrice {
  amount: 0;
  currency: string;
}

export interface DataGridFilters {
  pageSize: number;
  totalRows: number;
  pageNo: number;
  locationId?: string;
  searchText?: string;
  sortBy: string;
  sortOrder: string;
}
