import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserModel, UserStateModel } from './model';
import { cleanUser, setUser } from './operations';
import Cookies from 'js-cookie';

const user = Cookies.get('user') as UserModel;
console.log('user', user);
const initialState = {
  userModel: user ? user : {
    role: '',
    email: ''
  } as UserModel
} as UserStateModel;

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserModel>) {
      state.userModel = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(setUser.fulfilled, (state, action: PayloadAction<UserModel>) => {
      state.userModel = action.payload;
      Cookies.set('user', JSON.stringify(state), { expires: 1 });

    });
    builder.addCase(cleanUser.fulfilled, (state) => {
      state = {
        userModel: {
          role: '',
          email: ''
        } as UserModel
      } as UserStateModel;
    });
  }
});

export const userAction = userSlice.actions;
export default userSlice.reducer;
