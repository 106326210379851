import { Route, Routes } from 'react-router-dom';
import Layout from './layout/Layout';
import Pages from './pages';
import Home from './pages/Home';
import AuthRoutes from './components/routes/AuthRoutes';
import ProtectedRoutes from './components/routes/ProtectedRoutes';

const App = () => (
  <Layout>
    <Routes>
      <Route element={<AuthRoutes />}>
        <Route path='login' element={<Pages.Login />}></Route>
        <Route path='password_reminder' element={<Pages.PasswordReminder />}></Route>
        <Route path='password_reset' element={<Pages.PasswordReset />}></Route>
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path='/' element={<Pages.Home />}></Route>
        <Route path='/map' element={<Pages.Map />}></Route>
        <Route path='/white_list/:location' element={<Pages.WhiteList />}></Route>
        <Route path='/white_list/' element={<Pages.WhiteList />}></Route>
        <Route path='/parking_management' element={<Pages.ParkingManagement />}></Route>
        <Route path='/traffic' element={<Pages.TrafficAnalysis />}></Route>
        <Route path='/cameras' element={<Pages.Cameras />}></Route>
        <Route path='/user' element={<Pages.User />}></Route>
        <Route path='/location/:location' element={<Home />}></Route>
      </Route>
    </Routes>
  </Layout>
);

export default App;
