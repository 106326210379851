import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { DataGridFilters, TrafficAnalisysData } from './models';

export const fetchTrafficAnalisysRows = createAsyncThunk('trafic-analysis/fetch', async (filters: DataGridFilters): Promise<TrafficAnalisysData> => {
  try {
    const url = createUrl('', filters);
    const rows = await axiosInstance.get(url);
    return rows.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export const getImageData = async (photoUrl: string): Promise<string> => {
  try {
    const result = await axiosInstance.get(photoUrl);
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const exportData = createAsyncThunk('trafic-analysis/export', async (filters: DataGridFilters): Promise<string> => {
  try {
    const result = await axiosInstance.get(createUrl('/export', filters));
    let csvContent = 'data:text/csv;charset=utf-8,' + result.data;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    const dateStr = new Date().toISOString();
    link.setAttribute('download', `analiza_ruchu_${dateStr}.csv`);
    document.body.appendChild(link);
    link.click();
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

const createUrl = (endpoint: string, filters: DataGridFilters) => {
  const pageNum = Number(filters.pageNo) + 1;
  let url = `/v1/traffic-analysis${endpoint}`;
  url += '?pageSize=' + filters.pageSize;
  url += '&page=' + pageNum;
  if (filters.locationId && filters.locationId !== 'ALL') {
    url += '&locationUuid=' + filters.locationId;
  }
  if (filters.searchText) {
    url += '&textSearch=' + filters.searchText;
  }
  if (filters.sortBy) {
    url += '&sort=' + filters.sortBy;
  }
  if (filters.sortOrder) {
    url += '&sortDirection=' + filters.sortOrder;
  }

  return url;
};
