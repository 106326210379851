import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Barrier, BarriersStateModel } from './models';
import { fetchBarrierRows } from './operations';

const initialState = {
  rows: [],
  selectedLocation: '',
} as BarriersStateModel;

const barriersSlice = createSlice({
  name: 'barriers',
  initialState: initialState,
  reducers: {
    setSelectedLocation(state, action: PayloadAction<string>) {
      state.selectedLocation = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchBarrierRows.fulfilled, (state, action: PayloadAction<Barrier[]>) => {
      state.rows = action.payload;
    });
  },
});

export const { setSelectedLocation } = barriersSlice.actions;
export default barriersSlice.reducer;
