import {VehicleType, WhiteListRequest, WhiteListResponse, WhiteListRow, WhiteListStateModel} from './models';

export const getWhiteListRows = (state: WhiteListStateModel): WhiteListResponse => {
  return state.data;
};

export const getVehicleTypes = (state: WhiteListStateModel): VehicleType[] => {
  return state.vehicleTypes;
};

export const getSelectedEntry = (state: WhiteListStateModel): WhiteListRow | undefined => {
  return state.entry;
};

export const getOpenDialog = (state: WhiteListStateModel): boolean => {
  return state.openDialog;
};

export const getFilters = (state: WhiteListStateModel): WhiteListRequest => {
  return state.filters;
};
